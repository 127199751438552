@import "main.scss";

body.dark {
  background-color: black;
  color: white;
  p {
    color: white;
  }

  textarea {
    background-color: black;
    color: white;
  }

  a:link {
    color: slategray;
  }

  a:visited {
    color: darkslategray;
  }

  select {
    color: white;
    background-color: black;
  }

  button {
    background-color: black;
    color: white;
    border: 1px solid gray;
  }

  #comments-section {
    border: 1px solid #ffffff;
  }
}
