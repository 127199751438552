.voting {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;
}

.comment {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.comment-text {
  max-width: calc(100% - 43px);
}

.flair {
  margin-left: 10px;
  background-color: #edeff1;
  color: #1a1a1b;
}
