button {
  margin: 2px;
  border-radius: 3px;
  width: auto;
  height: auto;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;

  font-family: arial, sans-serif; /*input has OS specific font-family*/
  color: #069;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.transcription-typing {
  width: 1300px;
  height: 950px;
  float: right;
}

.postTitle {
  word-wrap: break-word;
  width: 450px;
}

#transcription {
  overflow-y: auto;
  width: 1300px;
  height: 600px;
  resize: none;
}

#transcription-textbox {
  display: flex;
  flex-direction: column;
  width: 80%;
  #character-count {
    text-align: right;
  }
}

#buttons {
  /*display: flex;
    flex-wrap: wrap;*/
}

#emoji-modal {
  max-height: 70vh;
}

#emojis {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 60vh;
}

#markdown-viewer {
  width: 1300px;
  height: 800px;
  float: right;
}

#transcribe-content {
  float: left;
}

#comments-section {
  width: 800px;
  float: right;
  border: 1px solid #000000;
}

#post-content {
  width: 900px;
  float: left;
}

#post-buttons {
  width: 500px;
}

#unclaim-button {
  float: right;
}

#display-markdown-button {
  float: right;
  margin-right: 300px;
}

.display-other-buttons {
  float: right;
}

#posts-table {
  width: 70%;
}

.thumbnail-slow {
  transition: max-width 0.7s;
}

.children {
  text-align: center;
  padding: 0;
  vertical-align: baseline;
  flex-grow: 0;
}

.parent {
  flex-direction: column;
  height: 100px;
}

/* User Flair Coloring */
.flair-grafeas-green {
  /* 51-100 */
  background-color: #228b22 !important;
  color: black;
}

.flair-grafeas-teal {
  /* 101-250 */
  background-color: #17a2a2 !important;
  color: black;
}

.flair-grafeas-purple {
  /* 251-500 */
  background-color: #7d26cd !important;
  color: black;
}

.flair-grafeas-golden {
  /* 501-1000 */
  background-color: #f1c40f !important;
  color: black;
}

.flair-grafeas-diamond {
  /* 1000-2500 */
  background-color: #b9f2ff !important;
  color: black;
}

.flair-grafeas-ruby {
  /* 2500+ */
  background-color: #d60633 !important;
  color: black;
}

.flair-grafeas {
  background-color: gray !important;
  color: black;
}

.progress_bg_color {
  background-color: gray;
}

#character-count {
  float: right;
}

.modal-header {
  display: flex;
  justify-content: space-between;
}

.expand-upwards {
  bottom: 0;
}

.post.removed {
  background: lightcoral;
}

.post-row {
  display: flex;
  flex-direction: row;
  max-width: 75%;
}

.post-vote {
  margin-top: 4px;
  margin-left: 10px;
}

.post-info {
  flex-grow: 1;
}

.post-image {
  min-width: 150px;
  max-width: 150px;
  padding: 10px;
  .image-container {
    min-height: 100%;
    &.loading {
      background: url(../assets/loading.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;

      img {
        visibility: hidden;
      }
    }
  }
}

.button_segment {
  width: 250px;
  margin-right: 150px;
  display: inline-block;
}

.button_segment2 {
  width: 100px;
  margin-right: 0px;
  display: inline-block;
}
